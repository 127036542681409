/**
 * 用户状态管理
 */
import http from '@/libs/http.js';
import router from '@/router'
import { loginApi, dictApi } from '@/api/common.js';
import { userApi } from '@/api/auth.js';
import { arrToObj, getTreeData, arrToObjByCommonPro, getLocalstorage, setLocalstorage } from "@/libs/basicTools.js";
export default {
  state: {
    token: getLocalstorage("token") || "",
    userId: "",
    dictItem: null,//字段字典信息列表
    routerPath: "",//初始化首页路由地址
    //process.env.NODE_ENV === 'production'?null:
    userInfo: null,
    // {
    //   "areaCode": "", "secCode": "", "name": "超级管理员", "userId": "792339399202590720", "orgId": "792058543808466944", "reportDates": "2018-12-31,2017-12-31,2016-12-31",
    //   "systemTitle": "上市公司智能监管系统", "email": "1299583460@qq.com", "resourceCode": "rcsp-gzw", "role": "wldl_sjb",
    //   "modules": [//模块
    //     //我的主页
    //     { "pcode": null, "code": "home", "name": "我的主页", "url": "/home", type: "01" },
    //     { "pcode": null, "code": "flowChart", "name": "流程图", "url": "/flowChart", type: "01" },
    //     //教学资源库
    //     // { "pcode": null, "code": "resourceLibTea", "name": "教学资源库(教师端)", "url": "/resourceLibTea", type: "01" },
    //     // { "pcode": null, "code": "resourceLibStu", "name": "教学资源库(学生端)", "url": "/resourceLibStu", type: "01" },
    //     // //教学管理
    //     { "pcode": null, "code": "teachManage", "name": "教学管理", "url": "", type: "01" },
    //     // { "pcode": "teachManage", "code": "coursewareManage", "name": "课件管理", "url": "/teachManage/coursewareManage", type: "01" },
    //     // { "pcode": "teachManage", "code": "facultyClassManage", "name": "班级管理", "url": "/teachManage/facultyClassManage", type: "01" },
    //     // // { "pcode": "teachManage", "code": "facultyClassAdminManage", "name": "院系班级管理（管理员）", "url": "/teachManage/facultyClassAdminManage" },
    //     // { "pcode": "teachManage", "code": "personManage", "name": "学生管理", "url": "/teachManage/personManage", type: "01" },
    //     // { "pcode": "teachManage", "code": "questionManage", "name": "试题管理", "url": "/teachManage/questionManage", type: "01" },
    //     // { "pcode": "teachManage", "code": "paperManage", "name": "试卷管理", "url": "/teachManage/paperManage", type: "01" },
    //     // { "pcode": "teachManage", "code": "questionGrades", "name": "试题成绩", "url": "/teachManage/questionGrades", type: "01" },
    //     // { "pcode": "teachManage", "code": "teachEvaluate", "name": "教学评价", "url": "/teachManage/teachEvaluate", type: "01" },
    //     { "pcode": "teachManage", "code": "caseManage", "name": "案例管理", "url": "/teachManage/caseManage", type: "01" },
    //     { "pcode": "teachManage", "code": "inEnvironmentManage", "name": "内部环境管理", "url": "/teachManage/inEnvironmentManage", type: "01" },
    //     // //在线学习与考试
    //     // { "pcode": null, "code": "onlineStudyExam", "name": "在线学习与考试", "url": "", type: "01" },
    //     // { "pcode": "onlineStudyExam", "code": "myCourses", "name": "我的课程", "url": "/onlineStudyExam/myCourses", type: "01" },
    //     // { "pcode": "onlineStudyExam", "code": "myTestPaper", "name": "我的试卷", "url": "/onlineStudyExam/myTestPaper" , type: "01"},
    //     // { "pcode": "onlineStudyExam", "code": "scoreQuery", "name": "分数查询", "url": "/onlineStudyExam/scoreQuery" , type: "01"},
    //     // //实训任务
    //     { "pcode": null, "code": "trainingTask", "name": "实训任务", "url": "", type: "01" },
    //     { "pcode": "trainingTask", "code": "controlActivity", "name": "控制活动", "url": "/trainingTask/controlActivity", type: "01" },
    //     { "pcode": "trainingTask", "code": "inEnvironment", "name": "内部环境", "url": "/trainingTask/inEnvironment", type: "01" },
    //     // //系统管理
    //     // { "pcode": null, "code": "systemManage", "name": "系统管理", "url": "", type: "01" },
    //     // { "pcode": "systemManage", "code": "userManage", "name": "用户管理", "url": "/systemManage/userManage", type: "01" },
    //     // { "pcode": "systemManage", "code": "roleManage", "name": "角色管理", "url": "/systemManage/roleManage", type: "01" },
    //     // { "pcode": "systemManage", "code": "moduleManage", "name": "资源管理", "url": "/systemManage/moduleManage", type: "01" },
    //     // { "pcode": "systemManage", "code": "facultyClassAdminManage", "name": "组织管理", "url": "/systemManage/facultyClassAdminManage", type: "01" },
    //   ],
    //   tabs: [],//页签
    //   userModuleTabs: {},//用户模块页签对象
    // },//用户相关信息
    menuBtnList: [],//当前路由菜单按钮集合

  },
  getters: {
    getFilePath: (state) => (root, code) => {
      let filePath = "";
      let dictItem = state.dictItem || {};
      let dictList = dictItem.dictFileStoragePath || [];
      for (let i = 0; i < dictList.length; i++) {
        if (root == dictList[i].itemCode) {
          filePath += dictList[i].itemDesc || "";
        }
        let children = dictList[i].children || [];
        for (let j = 0; j < children.length; j++) {
          if (code == children[j].itemCode) {
            filePath += "/" + children[j].itemDesc || "";
          }
        }
      }
      return filePath;
    },
    getDictItemDesc: (state) => (dictCode, itemCode) => {
      let dictItem = state.dictItem || {};
      let dictList = dictItem[dictCode] || [];
      for (let i = 0; i < dictList.length; i++) {
        if (itemCode == dictList[i].itemCode) {
          return dictList[i].itemDesc;
        }
      }
      return '';
    },
    getMenuBtnAuthByCode: (state) => (code) => {
      let menuBtnList = state.menuBtnList || [];
      for (let i = 0; i < menuBtnList.length; i++) {
        if (code == menuBtnList[i]) {
          return true;
        }
      }
      return false;
    },
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setDictItem(state, dictItem) {
      state.dictItem = dictItem;
    },
    setRouterPath(state, routerPath) {
      state.routerPath = routerPath;
    },
    setUserModuleTabs(state, userModuleTabs) {
      state.userModuleTabs = userModuleTabs;
    },
    setToken(state, token) {
      setLocalstorage("token", token);
      state.token = token;
    },
    setMenuBtnList(state, menuBtnList) {
      state.menuBtnList = menuBtnList;
    }
  },
  actions: {
    /**
     * 修改密码
     */
    updatePassword({ commit }, params) {
      return new Promise((resolve, reject) => {
        http.put(userApi.updatePassword, params).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      })
    },
    /**
     * 退出登录
     */
    logout({ commit }) {
      http.get(loginApi.logout).then(res => {
        commit("setToken", "");
        commit("setUserInfo", null);
        router.push({
          name: '登录'
        });
        location.reload();
      }).catch(err => {
      });
    },
    /**
     * 获取用户相关信息
     */
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        http.get(userApi.getUserResource).then(res => {
          if (res.data) {
            commit("setUserInfo", res.data || {});
          } else {
            router.push({
              name: '登录'
            });
          }
          resolve(res);
        }).catch(err => {
          // commit("setUserInfo", {});
          router.push({
            name: '登录'
          });
          reject(err);
        });
      })
    },
    /**
     * 初始化页面数据
     */
    initPageData({ commit, state }) {
      //用户页签数据转换
      var tabs = state.userInfo.tabs || [];
      var tabObj = arrToObjByCommonPro(tabs, "pcode");
      commit("setUserModuleTabs", tabObj);
      //页面模块配置、字典数据加载
      return new Promise((resolve, reject) => {
        http.all([
          http.get(dictApi.getAllDictDetail, {})
        ]).then(([dictRes]) => {
          var dictItem = arrToObj(dictRes.data || [], "dictCode", "dictItems");
          for (var pro in dictItem) {
            var item = dictItem[pro] || [];
            var treeData = [];
            // getTreeData(treeData, item, "itemCode", "itemPcode", null);
            if (treeData.length > 0) {
              dictItem[pro] = treeData;
            } else {
              dictItem[pro] = item;
            }
          }
          let dictType = arrToObj(dictRes.data || [], "dictCode", "dictType");
          dictItem.dictType = dictType;
          commit("setDictItem", dictItem);
          resolve([dictRes]);
        }).catch(err => {
          commit("setDictItem", {});
          reject(err);
        });
      })
    },
    /**
     * 获取首页路由地址
     */
    getRouterPath({ commit, state }) {
      var routerPath = "";
      var userInfo = state.userInfo || {};
      var menus = userInfo.modules || [];
      var pcode = "";
      for (var i = 0; i < menus.length; i++) {
        if (menus[i].pcode == null) {
          pcode = menus[i].code;
          routerPath = menus[i].url;
          break;
        }
      }
      for (var i = 0; i < menus.length; i++) {
        if (pcode == menus[i].pcode) {
          pcode = menus[i].code || "";
          if (menus[i].url != "" && menus[i].url != null) {
            routerPath = menus[i].url;
            break;
          }
        }
      }
      commit("setRouterPath", routerPath);
    },
    getCurrentMenuBtnList({ commit, state }, routerPath) {
      var userInfo = state.userInfo || {};
      var menus = userInfo.modules || [];
      let menuBtnList = [];
      let currentMenuCode = "";
      for (let i = 0; i < menus.length; i++) {
        if (routerPath == menus[i].url) {
          currentMenuCode = menus[i].code;
          break;
        }
      }
      for (let i = 0; i < menus.length; i++) {
        if (currentMenuCode == menus[i].pcode && "03" == menus[i].type) {
          menuBtnList.push(menus[i].code);
        }
      }
      commit("setMenuBtnList", menuBtnList);
    }
  }
}