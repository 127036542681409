<template>
  <el-dialog
    :class="fullscreen ? 'fullscreen-dialog' : 'main-dialog'"
    :top="top"
    :fullscreen="fullscreen"
    :title="title"
    v-bind="$attrs"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="100%"
    :show-close="fullscreen ? false : true"
    :style="fullscreen ? '' : 'margin:auto;width:' + width + 'px;height:100%;'"
    @closed="handleClose"
  >
    <slot name="body"></slot>
    <span slot="footer" v-if="showFooter">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: "showDialog",
    event: "change",
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 800,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    top: {
      type: String,
      default: "20px"
    }
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    showDialog(value) {
      this.dialogVisible = value;
    },
  },
  mounted() {
    this.dialogVisible = this.showDialog;
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.$emit("change", this.dialogVisible);
      this.$emit("after-close");
    },
    handleConfirm() {
      this.$emit("confirm");
    },
  },
};
</script>
<style lang="less">
.main-dialog {
  overflow: hidden;
  .el-dialog {
    height: calc(100% - 70px);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
    background: rgba(1, 1, 1, 0);
  }
  .el-dialog__header {
    background: #fff;
    border-bottom: 1px solid #dcdee2;
    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
    }
    .el-dialog__close {
      font-weight: bold;
      font-size: 16px;
      color: #17233d;
    }
  }
  .el-dialog__body {
    background: #fff;
    padding: 20px;
    max-height: calc(100% - 105px);
    overflow-y: auto;
  }
  .el-dialog__footer {
    background: #fff;
  }
}
.fullscreen-dialog {
  .el-dialog {
    background: #f5f7f9;
    min-width: 1200px;

    overflow: auto;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      max-width: 1400px;
      margin: auto;
    }
  }
}
</style>