import axios from 'axios';
import store from "@/store";
import { Message } from 'element-ui';
import qs from 'qs';
import router from '@/router'
axios.defaults.timeout = 300000;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
/**
 * 数据请求流程操作
 */
class HttpRequest {
  /**
   * 拦截操作
   * @param {*} instance 请求实例对象 
   */
  interceptors(instance) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      //根路径赋值
      //不用客户调用不同服务地址配置
      // var userInfo = store.state.user.userInfo || {};
      // var resourceCode = userInfo.resourceCode || "";
      // if (config.url.indexOf("api-rcsp") >= 0) {
      //   if (resourceCode == null || resourceCode == "") {
      //     Message.warning({ message: "该用户没有数据权限，请联系系统管理员！", showClose: true });
      //     store.state.app.errorNums++;
      //     return false;
      //   }
      //   config.url = config.url.replace(/api-rcsp/, ("api-rcsp/" + resourceCode));
      // }
      //添加认证信息
      if (store.state.user.token) {
        config.headers.common['Authorization'] = "Bearer " + store.state.user.token;
      }
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      store.state.app.errorNums = 0;
      //用户token失效，清楚缓存且跳到登录页面
      if (res.data.code == "1002" || res.data.code == "1004" || res.data.code == "1005") {
        store.commit('setToken', "")
        router.push({
          name: '登录'
        });
        Message.closeAll();
        Message.error({ message: res.data.errorMessage || "用户登录已失效，请重新登录！", showClose: true });
        return res.data;
      }
      //刷新token并重新赋值
      // if (res.headers.authorization || res.headers.AUTHORIZATION || res.headers.Authorization) {
      //   var authorization = res.headers.authorization || res.headers.AUTHORIZATION || res.headers.Authorization;
      //   store.commit('setToken', authorization)
      // }
      let headers = res.headers;
      if(typeof res.data=="object"){
        res.data.headers=headers;
      }
      return res.data;
    }, error => {
      //认证失败重新跳入登录页面
      if (error.response && error.response.status === 500 && error.response.data.message === 'refresh fail') {
        window.location.href = resourceServiceUrl.bpssLogin + window.location.href;
      }
      //==============  错误处理  ====================
      if (error && error.response) {
        switch (error.response.status) {
          case 400: error.message = '请求错误(400)'; break;
          case 401: error.message = '未授权，请重新登录(401)'; break;
          case 403: error.message = '拒绝访问(403)'; break;
          case 404: error.message = '请求出错(404)'; break;
          case 408: error.message = '请求超时(408)'; break;
          case 500: error.message = '服务器错误(500)'; break;
          case 501: error.message = '服务未实现(501)'; break;
          case 502: error.message = '网络错误(502)'; break;
          case 503: error.message = '服务不可用(503)'; break;
          case 504: error.message = '网络超时(504)'; break;
          case 505: error.message = 'HTTP版本不受支持(505)'; break;
          default: error.message = `连接出错(${error.response.status})!`;
        }
      } else {
        error.message = '连接服务器失败!'
      }
      if (store.state.app.errorNums == 0) {
        Message.closeAll();
        Message.error({ message: error.message, showClose: true });
      }
      store.state.app.errorNums++;
      return Promise.reject(error)
    })
  }
  /**
   * 自定义请求配置
   * @param {*} options 自定义配置
   */
  request(options) {
    const instance = axios.create()
    this.interceptors(instance)
    return instance(options)
  }
  /**
   * get请求
   * @param {*} api 接口请求地址
   * @param {*} params 接口请求参数
   */
  get(api, params, dataType) {
    const instance = axios.create()
    this.interceptors(instance)
    return instance.get(api, {
      params: params,
      responseType: dataType,
      paramsSerializer: params => qs.stringify(params)
    });
  }
  /**
   * delete请求
   * @param {*} api 接口请求地址
   * @param {*} params 接口请求参数
   */
  delete(api, params) {
    const instance = axios.create()
    this.interceptors(instance)
    return instance.delete(api, {
      params: params,
      paramsSerializer: params => qs.stringify(params)
    });
  }
  /**
   * post请求
   * @param {*} api 接口请求地址
   * @param {*} params 接口请求参数
   */
  post(api, params, dataType) {
    const instance = axios.create()
    this.interceptors(instance)
    return instance.post(api, params || {}, dataType || {});
  }
  /**
   * put请求
   * @param {*} api 接口请求地址
   * @param {*} params 接口请求参数
   */
  put(api, params, dataType) {
    const instance = axios.create()
    this.interceptors(instance)
    return instance.put(api, params || {}, dataType || {});
  }
  /**
   * 文件导出
   * @param {*} url 接口地址
   * @param {*} params 接口请求参数
   * @param {*} fileName 导出文件名
   */
  exportFile(url, params, fileName, method) {
    const instance = axios.create()
    this.interceptors(instance)
    return instance({
      method: method || "POST",
      url: url,
      data: params,
      params: method == "GET" ? params : null,
      responseType: 'blob'
    }).then(function (res) {
      //附件名获取
      if(!fileName||fileName.indexOf(".")<0){
        let headers=res.headers;
        let contentDisposition=headers["content-disposition"]||"";
        let reqFileName=contentDisposition.substring(contentDisposition.indexOf("fileName="),contentDisposition.length);
        reqFileName=reqFileName.replace("fileName=","").replace(/\"/g,"");
        if(!fileName){
          fileName=reqFileName;
        }else{
          fileName+=reqFileName.substring(reqFileName.indexOf("."),reqFileName.length);
        }
      }
      var content = res;
      if (content == null || content == "application/json") {
        Message.error({ message: "下载失败！", showClose: true });
        return;
      }
      var blob = new Blob([content], { type: content.type })
      if ('download' in document.createElement('a')) {
        var elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        setTimeout(function () {
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }, 3000);
        return res;
      } else {
        window.navigator.msSaveBlob(blob, fileName);
        return res;
      }
    }).catch(function (error) {
      return error;
    });
  }
  /**
   * 多个并发请求
   * @param {*} https 请求对象集合
   */
  all(https) {
    return axios.all(https).then(axios.spread((...res) => {
      return res;
    }));
  }
}
const http = new HttpRequest()
export default http