/**
 * 系统应用状态管理
 */
import { getLocalstorage, setLocalstorage } from "@/libs/basicTools.js";
export default {
  state: {
    clientHeight: 0,//主内容高度
    searchParamsCache: getLocalstorage("searchParamsCache") || {},//查询组件参数缓存
    breadcrumbList: [],//面包屑列表
  },
  getters: {
    getContentHeight: (state) => (otherHeight) => {
      return (
        (state.clientHeight > 372
          ? state.clientHeight
          : 372) - (otherHeight || 0)
      );
    }
  },
  mutations: {
    setSearchParamsCache(state, searchParamsCache) {
      state.searchParamsCache = searchParamsCache;
    },
    setClientHeight(state, clientHeight) {
      state.clientHeight = clientHeight;
    },
    setBreadcrumbList(state, breadcrumbList) {
      state.breadcrumbList = breadcrumbList;
    }
  },
  actions: {
    /**
     * 获取查询组件参数缓存
     */
    setSearchParamsCache({ commit, state }, { cacheKey, cacheObj }) {
      if (cacheKey == null || cacheKey == "") { return; }
      var searchParamsCache = state.searchParamsCache || {};
      searchParamsCache[cacheKey] = Object.assign({}, searchParamsCache[cacheKey], cacheObj);
      setLocalstorage("searchParamsCache", searchParamsCache);
      commit("setSearchParamsCache", searchParamsCache);
    },
    /**
     * 设置客户端高度
     */
    setClientHeight({ commit, state }, isResize) {
      var clientHeight = state.clientHeight;
      if (clientHeight == 0 || isResize) {
        var scrollItem = document.getElementById("scrollItem");
        if (scrollItem) {
          commit("setClientHeight", scrollItem.clientHeight);
        }
      }
    },
    /** 获取面包屑列表*/
    getBreadcrumbList({ commit, state }, router) {
      let breadcrumbList = [];
      let matched = router.matched || [];
      for (let i = 0; i < matched.length; i++) {
        let item = matched[i] || {};
        if (item.name && item.path && item.path.indexOf("trainingTask")<0) {
          breadcrumbList.push({
            path: item.path,
            name: item.name
          });
        }
      }
      commit("setBreadcrumbList", breadcrumbList);
    },
    /**新增面包屑对象 */
    addBreadcrumbItem({ commit, state }, item) {
      let breadcrumbList = state.breadcrumbList || [];
      breadcrumbList.push(item);
      commit("setBreadcrumbList", breadcrumbList);
    },
    /**删除面包屑对象 */
    delBreadcrumbItem({ commit, state }, index) {
      let breadcrumbList = state.breadcrumbList || [];
      breadcrumbList.splice(index, 1);
      commit("setBreadcrumbList", breadcrumbList);
    }
  }
}