<template>
  <el-container class="h_100">
    <el-header class="pd0">
      <Header class="main-header" @on-select-menu="turnToPage"></Header>
    </el-header>
    <el-main class="h_100" id="scrollItem">
      <div
        v-if="breadcrumbList.length > 1"
        style="
          height: 25px;
          padding-top: 15px;
          padding-bottom: 10px;
          position: relative;
        "
      >
        <el-breadcrumb class="pta" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            v-for="(item, index) in breadcrumbList"
            :key="item.path"
            v-show="index != 0"
            class="fz20"
            >{{ item.name }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <keep-alive>
        <router-view v-if="isRouterAlive" />
      </keep-alive>
    </el-main>
    <el-footer class="bg_background" style="height: 40px">
      <p class="tac fz12 c_sub">
        CopyRight © 迪博企业风险管理技术有限公司 All Rights Reserved.
      </p>
    </el-footer>
    <div class="backTop" v-if="isBackTop" @click="backTop">
      <i class="el-icon-arrow-up fz30"></i>
    </div>
  </el-container>
</template>

<script>
import Header from "_c/header";
import echarts from "echarts";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    Header,
  },
  data: function () {
    return {
      isBackTop: false,
      scrollItem: null,
      isRouterAlive: true,
    };
  },
  computed: {
    ...mapState({
      breadcrumbList: (state) => state.app.breadcrumbList || [],
    }),
  },
  mounted: function () {
    window.onresize = () => {
      //窗口大小改变事件
      //重置echarts图形
      var doms = document.getElementsByClassName("echarts");
      for (var i = 0; i < doms.length; i++) {
        echarts.init(doms[i], "macarons").resize();
      }
      //获取主内容高度
      this.setClientHeight(true);
    };
    //监听窗口滚动事件，添加返回顶部按钮
    window.addEventListener(
      "scroll",
      (item) => {
        //返回顶部
        var scrollItem = item.target;
        if (
          item.target.id !== "scrollItem" &&
          item.target.className !== "el-dialog is-fullscreen"
        ) {
          return;
        }
        this.scrollItem = scrollItem;
        if (item.target.scrollTop > 200) {
          this.isBackTop = true;
        } else {
          this.isBackTop = false;
        }
      },
      true
    );
  },
  methods: {
    ...mapActions([
      "setClientHeight", //设置客户端高度
    ]),
    turnToPage(route) {
      //菜单选择事件
      let { path, params, query } = {};
      if (typeof route === "string") path = route;
      else {
        path = route.url;
        params = route.params;
        query = route.query;
      }
      if (path.indexOf("isTurnByHref_") > -1) {
        window.open(path.split("_")[1]);
        return;
      }
      this.isBackTop = false;
      this.$router.push({
        //根据选择名称路由跳转页面
        path,
        params,
        query,
      });
      this.reload();
    },
    backTop() {
      this.isBackTop = false;
      this.scrollItem.scrollTop = 0;
    },
    reload() {
      // this.isRouterAlive = false;
      // this.$nextTick(function () {
      //   this.isRouterAlive = true;
      // });
    },
  },
};
</script>
<style lang="less">
.backTop {
  color: #fff;
  font-size: 24px;
  padding: 8px 12px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  position: absolute;
  z-index: 5000;
  width: 40px;
  height: 35px;
  bottom: 70px;
  right: 50px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.2s ease-in-out;
  }
}
.main-header {
  // background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  z-index: 500;
  background-color: #2a82e4;
}
.el-header {
  height: auto !important;
}
.el-container {
  background: url("~@/assets/images/paper_test_bg.png") no-repeat;
  background-size: 100% 100%;
}
.el-main {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background-color: rgba(245, 247, 249, 0.7);
}
</style>
