/**
 * 数据处理基础工具
 */

/**
 * 获取浏览器localstorage缓存
 * @param {*} pro 对象名 
 */
export const getLocalstorage = function (pro) {
  var text = window.localStorage.getItem(pro);
  //对象或者数组解析
  if (text && (text.indexOf("{") == 0 && text.indexOf("}") >= 0 && text.indexOf(":") >= 0 ||
    text.indexOf("[") == 0 && text.indexOf("]") >= 0)) {
    try {
      var objArr = JSON.parse(text);
      return objArr;
    } catch {
      return text;
    }
  } else {
    return text;
  }
}

/**
 * 设置浏览器localstorage缓存
 * @param {*} pro 对象名
 * @param {*} value 对象值
 */
export const setLocalstorage = function (pro, value) {
  if (pro == null || pro == "" || value == "" || value == null) { window.localStorage.removeItem(pro); return; }
  if (typeof value != "string") { value = JSON.stringify(value); }
  window.localStorage.setItem(pro, value);
}

/**
 * 数字添加千分位
 * @param {*} num 数字数据
 */
export const formatThousands = function (num) {
  if (num == null || num == "") { return num; }
  var values = num.toString().split(".");
  var reg = /\d{1,3}(?=(\d{3})+$)/g;
  return ((values[0] + '').replace(reg, '$&,')) + (values[1] ? "." + values[1] : "");
}

/**
 * 去除重复数组数据
 * @param {*} arr 目标数组数据
 */
export const removeRepeatArr = function (arr) {
  return arr.filter(function (item, index, arr) {
    //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
    return arr.indexOf(item, 0) === index;
  });
}

/*
* 随机生成字符串
* range 字符串长度
*/
export const randomStr = (range) => {
  var str = "",
    arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  for (var i = 0; i < range; i++) {
    var pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  return str;
}
/**
 * 数组转对象
 * @param {*} arr 数组集合
 * @param {*} key 对象属性取值字段
 * @param {*} value 属性值取值字段
 */
export const arrToObj = (arr, key, value) => {
  var obj = {};
  for (var i = 0; i < arr.length; i++) {
    var item = arr[i];
    obj[item[key]] = item[value];
  }
  return obj;
}

/**
* 从对象集合中通过某个属性获取该属性的值的数组集合
* @param {*} list 对象数组
* @param {*} pro 属性
*/
export const getArrByProFromList = function (list, pro) {
  var arr = [];
  for (var i = 0; i < list.length; i++) {
    arr.push(list[i][pro]);
  }
  return arr;
}
/**
 * 计算数据集合通用方法
 */
export const calculateDataList = {
  /**
   * 集合乘法
   * @param {*} dataList 集合数据
   * @param {*} pro 计算属性
   * @param {*} multiplyNum 被乘数
   */
  multiplyBy: (dataList, pro, multiplyNum) => {
    for (var i = 0; i < dataList.length; i++) {
      var item = dataList[i];
      //对象集合
      if (pro && typeof item == "object") {
        var value = item[pro];
        if (!isNaN(value) && value != "") {
          item[pro] = (Number(value) * multiplyNum).toFixed(4);
        }
      }
      //数组集合
      if (pro == null && typeof item != "object") {
        var value = item;
        if (!isNaN(value) && value != "") {
          dataList[i] = (Number(value) * multiplyNum).toFixed(4);
        }
      }
    }
  },
  /**
   * 集合除法
   * @param {*} dataList 集合数据
   * @param {*} pro 计算属性
   * @param {*} divideNum 被除数
   */
  divideBy: (dataList, pro, divideNum) => {
    for (var i = 0; i < dataList.length; i++) {
      var item = dataList[i];
      //对象集合
      if (pro && typeof item == "object") {
        var value = item[pro];
        if (!isNaN(value) && value != "") {
          item[pro] = (Number(value) / divideNum).toFixed(4);
        }
      }
      //数组集合
      if (pro == null && typeof item != "object") {
        var value = item;
        if (!isNaN(value) && value != "") {
          dataList[i] = (Number(value) / divideNum).toFixed(4);
        }
      }
    }
  },
  /**
   * 集合保留小数位
   * @param {*} dataList 集合数据
   * @param {*} pro 计算属性
   * @param {*} fixedNum 小数位数
   */
  toFixed: (dataList, pro, fixedNum) => {
    for (var i = 0; i < dataList.length; i++) {
      var item = dataList[i];
      //对象集合
      if (pro && typeof item == "object") {
        var value = item[pro];
        if (!isNaN(value) && value != "") {
          item[pro] = Number(value).toFixed(fixedNum);
        }
      }
      //数组集合
      if (pro == null && typeof item != "object") {
        var value = item;
        if (!isNaN(value) && value != "") {
          dataList[i] = Number(value).toFixed(fixedNum);
        }
      }
    }
  }
}

/**
 * sort排序
 * @param {*} dataList 集合数据
 * @param {*} pro 排序属性 
 * @param {*} sortType 排序方法  
 */
export const sortDataList = (dataList, pro, sortType) => {
  var sortValue = (a, b) => {
    var isMax = true;
    if (pro == null) {
      //+a等于Number(a)
      isMax = (isNaN(+a) ? a : +a) > (isNaN(+b) ? b : +b);
    } else {
      isMax = (isNaN(+a[pro]) ? a[pro] : +a[pro]) > (isNaN(+b[pro]) ? b[pro] : +b[pro]);
    }
    if (sortType == "desc") {
      return isMax ? -1 : 1;
    } else {
      return isMax ? 1 : -1;
    }
  }
  if (dataList && dataList.length > 1) {
    dataList.sort(sortValue);
  }
}
/**
 * 获取集合数据的平均值
 * @param {*} list 
 */
export const getListAverage = (list) => {
  var average = 0;
  var total = 0;
  for (var i = 0; i < list.length; i++) {
    if (!isNaN(list[i])) {
      total += Number(list[i]);
    }
  }
  if (total == 0) {
    return 0;
  } else {
    return (total / list.length).toFixed(2);
  }
}
/**
 * 获取树形结构数据
 * @param {*} treeData 树形结构数据对象
 * @param {*} dataList 源数据对象
 * @param {*} codeKey code属性
 * @param {*} pcodeKey pcode属性
 * @param {*} pcodeValue pcode值
 */
export const getTreeData = (treeData, dataList, codeKey, pcodeKey, pcodeValue) => {
  for (var i = 0; i < dataList.length; i++) {
    var item = dataList[i];
    if (item[pcodeKey] == pcodeValue || (item[pcodeKey] == null || item[pcodeKey] == "") && (pcodeValue == null || pcodeValue == "")) {
      var children = [];
      getTreeData(children, dataList, codeKey, pcodeKey, item[codeKey]);
      item.children = children;
      treeData.push(item);
    }
  }
}
/**
 * 数组转对象-公共属性值取为键值
 * @param {*} arr 
 * @param {*} pro 
 */
export const arrToObjByCommonPro = (arr, pro) => {
  var newObj = {};
  for (var i = 0; i < arr.length; i++) {
    var item = arr[i];
    if (newObj.hasOwnProperty(item[pro])) {
      newObj[item[pro]].push(item);
    } else {
      newObj[item[pro]] = [item];
    }
  }
  return newObj;
}

/**
 * 获取光标之前、后的文本
 * @param {*} id 
 * @param {*} sourceText 
 */
export const getCursorFrontBehindText = (id, sourceText) => {
  var cursorObj = {};
  if (sourceText == null) {
    sourceText = "";
  }
  var cursorIndex = getCursortPosition(id);
  cursorObj = {
    cursorFrontText: sourceText.substring(0, cursorIndex),
    cursorBehindText: sourceText.substring(cursorIndex),
    cursorIndex: cursorIndex
  }
  return cursorObj;
}
/**
 * 获取光标当前所处位置
 * @param {*} id 
 */
export const getCursortPosition = (id) => {
  var inputDom = document.getElementById(id);
  var cursorIndex = 0;
  if (document.selection) {
    // IE Support
    inputDom.focus();
    var range = document.selection.createRange();
    range.moveStart('character', -inputDom.value.length);
    cursorIndex = range.text.length;
  } else if (inputDom.selectionStart || inputDom.selectionStart == 0) {
    // another support
    cursorIndex = inputDom.selectionStart;
  }
  return cursorIndex;
}

/**
* 在光标后插入文本
* 参数：
*     id  [JavaScript DOM String] 当前对象
*     value  [String]  要插入的文本
*/
export const insertAfterText = (id, value) => {
  var inputDom = document.getElementById(id);
  var selectRange;
  if (document.selection) {
    // IE Support
    inputDom.focus();
    selectRange = document.selection.createRange();
    selectRange.text = value;
    inputDom.focus();
  } else if (inputDom.selectionStart || inputDom.selectionStart == '0') {
    // Firefox support
    var startPos = inputDom.selectionStart;
    var endPos = inputDom.selectionEnd;
    var scrollLeft = inputDom.scrollLeft;
    inputDom.value = inputDom.value.substring(0, startPos) + value + inputDom.value.substring(endPos, inputDom.value.length);
    inputDom.focus();
    inputDom.selectionStart = startPos + value.length;
    inputDom.selectionEnd = startPos + value.length;
    inputDom.scrollLeft = (inputDom.value.substring(0, startPos) + value).length * 10 - 10;
  }
  else {
    inputDom.value += value;
    inputDom.focus();
  }
}

/**
合并单元格方法集合
*/
export const spanRowFunc = {
  /**获取字段行合并数据 */
  getSpanRowList: (columns, tableData) => {
    var spanRowList = [];
    columns.forEach((item, index) => {
      //循环字段判断是否需要合并
      if (item.isCombine) {
        var key = item.key;
        //判断上一个字段是否为合并字段，若是则取值
        let lastKey = "";
        if (index > 0 && columns[index - 1].isCombine) {
          lastKey = columns[index - 1].key;
        }
        //循环数据判断是否相连行字段数据相同
        for (var i = 0; i < tableData.length; i++) {
          var spanRowObj = { columnIndex: index, rowIndex: i, rowspan: 1 };
          for (var j = i + 1; j < tableData.length; j++) {
            if (
              j == spanRowObj.rowspan + i &&
              tableData[i][key] == tableData[j][key]
              && (!lastKey || tableData[i][lastKey] == tableData[j][lastKey])
            ) {
              spanRowObj.rowspan++;
              spanRowList.push({
                columnIndex: index,
                rowIndex: j,
                rowspan: 0,
              });
            } else {
              break;
            }
          }
          if (spanRowObj.rowspan > 1) {
            spanRowList.push(spanRowObj);
            i += spanRowObj.rowspan - 1;
          }
        }
      }
    });
    return spanRowList;
  },
  /**合并行或列处理 */
  handleSpan(rowIndex, columnIndex, spanRowList) {
    for (var i = 0; i < spanRowList.length; i++) {
      var item = spanRowList[i];
      if (rowIndex == item.rowIndex && columnIndex == item.columnIndex) {
        return {
          rowspan: item.rowspan,
          colspan: item.rowspan > 0 ? 1 : 0,
        };
      }
    }
  },
}

/**
 * 格式化JSON源码(对象转换为JSON文本)
 * @param {*} txt 
 * @param {*} compress 是否为压缩模式
 */
export const formatJson = (txt, compress) => {
  var indentChar = '    ';
  if (/^\s*$/.test(txt)) {
    alert('数据为空,无法格式化! ');
    return;
  }
  try { var data = eval('(' + txt + ')'); }
  catch (e) {
    alert('数据源语法错误,格式化失败! 错误信息: ' + e.description, 'err');
    return;
  };
  var draw = [], last = false, This = this, line = compress ? '' : '\n', nodeCount = 0, maxDepth = 0;

  var notify = function (name, value, isLast, indent/*缩进*/, formObj) {
    nodeCount++;/*节点计数*/
    for (var i = 0, tab = ''; i < indent; i++)tab += indentChar;/* 缩进HTML */
    tab = compress ? '' : tab;/*压缩模式忽略缩进*/
    maxDepth = ++indent;/*缩进递增并记录*/
    if (value && value.constructor == Array) {/*处理数组*/
      draw.push(tab + (formObj ? ('"' + name + '":') : '') + '[' + line);/*缩进'[' 然后换行*/
      for (var i = 0; i < value.length; i++)
        notify(i, value[i], i == value.length - 1, indent, false);
      draw.push(tab + ']' + (isLast ? line : (',' + line)));/*缩进']'换行,若非尾元素则添加逗号*/
    } else if (value && typeof value == 'object') {/*处理对象*/
      draw.push(tab + (formObj ? ('"' + name + '":') : '') + '{' + line);/*缩进'{' 然后换行*/
      var len = 0, i = 0;
      for (var key in value) len++;
      for (var key in value) notify(key, value[key], ++i == len, indent, true);
      draw.push(tab + '}' + (isLast ? line : (',' + line)));/*缩进'}'换行,若非尾元素则添加逗号*/
    } else {
      if (typeof value == 'string') value = '"' + value + '"';
      draw.push(tab + (formObj ? ('"' + name + '":') : '') + value + (isLast ? '' : ',') + line);
    };
  };
  var isLast = true, indent = 0;
  notify('', data, isLast, indent, false);
  return draw.join('');
}
/**
 * 将数字转换为汉字
 * @param {*} num
 */
export const numToStr = (num) => {
  let str = '';
  if (num / 10 >= 1) {
    let char = numToChar(parseInt(num / 10));
    if (char == '一') {
      char = '十';
    } else {
      char = char + '十'
    }
    str += char;
  }
  if (num % 10 > 0) {
    let char = numToChar(num % 10)
    str += char;
  }
  return str;
}
const numToChar = (num) => {
  let char = '';
  switch (num) {
    case 1:
      char = '一'
      break;
    case 2:
      char = '二'
      break;
    case 3:
      char = '三'
      break;
    case 4:
      char = '四'
      break;
    case 5:
      char = '五'
      break;
    case 6:
      char = '六'
      break;
    case 7:
      char = '七'
      break;
    case 8:
      char = '八'
      break;
    case 9:
      char = '九'
      break;
  }
  return char;
}

export const ellipsis = (value, len) => {
  if (!value) return ''
  if (value.length > len) {
    return value.slice(0, len) + '...'
  }
  return value
}

/**
 * 数组乱序
 * @param {*} sourceArr 原数组列表
 */
export const arrMullOrder = (sourceArr) => {
  let newArr = [];
  let sourceLength = sourceArr.length;
  let afterLength = sourceArr.length;
  for (let i = 0; i < sourceLength; i++) {
    let randomNum = Math.floor(Math.random() * afterLength);
    newArr.push(sourceArr[randomNum]);
    sourceArr.splice(randomNum, 1);
    afterLength = sourceArr.length;
  }
  return newArr;
}