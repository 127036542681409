<template>
  <el-table
    :span-method="spanMethod"
    ref="table"
    :highlight-current-row="highlightCurrentRow"
    :data="tableData"
    :border="border"
    header-row-class-name="header-row"
    @selection-change="selectionChange"
    @row-click="rowClick"
    size="mini"
    :max-height="maxHeight"
    :height="height"
    :cell-class-name="cellClassName"
    :show-header="showHeader"
    :row-class-name="rowClassName"
  >
    <template v-for="(item, index) in columns">
      <!-- 一级表头---自定义显示数据 -->
      <el-table-column
        :key="index"
        :type="item.type"
        :label="item.title"
        :width="item.width"
        :min-width="item.minWidth"
        :class-name="item.className"
        :label-class-name="item.labelClassName"
        :align="item.align"
        :fixed="item.fixed"
        :show-overflow-tooltip="item.showOverflowTooltip"
        v-if="item.customize"
      >
        <template slot-scope="scope">
          <slot
            v-bind:row="scope.row"
            v-bind:index="scope.$index"
            v-bind:column="scope.column"
            :name="item.key"
          ></slot>
        </template>
      </el-table-column>
      <!-- 一级表头---默认 -->
      <el-table-column
        :key="index"
        :type="item.type"
        :prop="item.key"
        :label="item.title"
        :width="item.width"
        :min-width="item.minWidth"
        :class-name="item.className"
        :label-class-name="item.labelClassName"
        :align="item.align"
        :fixed="item.fixed"
        :show-overflow-tooltip="item.showOverflowTooltip"
        :selectable="item.selectable"
        v-else
      >
        <template v-for="(itemNext, indexNext) in item.children || []">
          <!-- 二级表头---自定义显示数据 -->
          <el-table-column
            :key="indexNext"
            :width="itemNext.width"
            :min-width="itemNext.minWidth"
            :class-name="itemNext.className"
            :label-class-name="itemNext.labelClassName"
            :prop="itemNext.key"
            :label="itemNext.title"
            :align="itemNext.align"
            :fixed="itemNext.fixed"
            :show-overflow-tooltip="itemNext.showOverflowTooltip"
            v-if="itemNext.customize"
          >
            <template slot-scope="scope">
              <slot
                v-bind:row="scope.row"
                v-bind:index="scope.$index"
                v-bind:column="scope.column"
                :name="itemNext.key"
              ></slot>
            </template>
          </el-table-column>
          <!-- 二级表头---默认 -->
          <el-table-column
            :key="indexNext"
            :width="itemNext.width"
            :min-width="itemNext.minWidth"
            :class-name="itemNext.className"
            :label-class-name="itemNext.labelClassName"
            :prop="itemNext.key"
            :label="itemNext.title"
            :align="itemNext.align"
            :show-overflow-tooltip="itemNext.showOverflowTooltip"
            :fixed="itemNext.fixed"
            v-else
          >
            <template
              v-for="(itemNextTwo, indexNextTwo) in itemNext.children || []"
            >
              <!-- 三级表头---自定义显示数据 -->
              <el-table-column
                :key="indexNextTwo"
                :width="itemNextTwo.width"
                :min-width="itemNextTwo.minWidth"
                :class-name="itemNextTwo.className"
                :label-class-name="itemNextTwo.labelClassName"
                :prop="itemNextTwo.key"
                :label="itemNextTwo.title"
                :align="itemNextTwo.align"
                :fixed="itemNextTwo.fixed"
                :show-overflow-tooltip="itemNextTwo.showOverflowTooltip"
                v-if="itemNextTwo.customize"
              >
                <template slot-scope="scope">
                  <slot
                    v-bind:row="scope.row"
                    v-bind:index="scope.$index"
                    v-bind:column="scope.column"
                    :name="itemNextTwo.key"
                  ></slot>
                </template>
              </el-table-column>
              <!-- 三级表头---默认 -->
              <el-table-column
                :key="indexNextTwo"
                :width="itemNextTwo.width"
                :min-width="itemNextTwo.minWidth"
                :class-name="itemNextTwo.className"
                :label-class-name="itemNextTwo.labelClassName"
                :prop="itemNextTwo.key"
                :label="itemNextTwo.title"
                :align="itemNextTwo.align"
                :show-overflow-tooltip="itemNextTwo.showOverflowTooltip"
                :fixed="itemNextTwo.fixed"
                v-else
              ></el-table-column>
            </template>
          </el-table-column>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
import { getArrByProFromList } from "@/libs/basicTools.js";
export default {
  props: {
    border: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    height: {
      type: Number,
      default: null,
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    spanMethod: {
      type: Function,
      default: null,
    },
    highlightCurrentRow: {
      type: Boolean,
      default: false,
    },
    cellClassName: {
      type: Function,
      default: null,
    },
    rowClassName: {
      type: Function,
      default: null,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    selectionChange(selection) {
      //当选择项发生变化时会触发该事件
      var idsList = getArrByProFromList(selection || [], "id");
      this.$emit("selection-change", selection, idsList);
    },
    rowClick(row) {
      //当选择项发生变化时会触发该事件
      this.$emit("row-click", row);
    },
  },
};
</script>
<style lang="less">
.header-row {
  th {
    background-color: #f5f8fc !important;
    color: #606266;
    font-size: 13px;
  }
}
.el-tooltip__popper {
  max-width: 400px;
  z-index: 3000 !important;
}
.el-table__empty-text {
  font-size: 16px;
  color: #808695;
}
.el-table .el-checkbox__inner {
  // border-color: #409eff;
  border: 1px solid rgb(192, 196, 204);
  width: 16px;
  height: 16px;
}
.el-table__row:hover > td {
  background-color: rgb(217, 236, 255) !important;
}
</style>
