<template>
  <el-row>
    <el-col class="pdt20 w300 mgl20" style="line-height: 20px">
      <img :src="sysConfig.logo" class="vat mgr10" style="height: 18px" />
      <span class="fz18 bwb c_white">{{ sysConfig.title }}</span>
    </el-col>
    <el-col class="hidden-menu" style="width: calc(100% - 420px); height: 60px">
      <el-menu
        class="main-menu"
        :default-active="$route.path"
        mode="horizontal"
        @select="handleSelect"
      >
        <template v-for="(item, index) in menus">
          <!-- <template
            v-if="
              !(
                item.code == 'trainingTask' &&
                (item.children == null || item.children.length == 0)
              )
            "
          > -->
          <!-- 一级菜单 -->
          <el-menu-item
            :index="item.url"
            :key="index"
            v-if="item.children == null && item.url != ''"
            >{{ item.name }}</el-menu-item
          >
          <el-submenu :index="item.code" :key="index" v-else>
            <template slot="title">{{ item.name }}</template>
            <template v-for="(itemLevel2, indexLevel2) in item.children">
              <!-- 二级菜单 -->
              <el-menu-item
                :index="itemLevel2.url"
                :key="indexLevel2"
                v-if="itemLevel2.children == null && itemLevel2.url != ''"
                >{{ itemLevel2.name }}</el-menu-item
              >
              <el-submenu :index="itemLevel2.code" :key="indexLevel2" v-else>
                <template slot="title">{{ itemLevel2.name }}</template>
                <template
                  v-for="(itemLevel3, indexLevel3) in itemLevel2.children"
                >
                  <!-- 三级菜单 -->
                  <el-menu-item :index="itemLevel3.url" :key="indexLevel3">{{
                    itemLevel3.name
                  }}</el-menu-item>
                </template>
              </el-submenu>
            </template>
          </el-submenu>
          <!-- </template> -->
        </template>
      </el-menu>
    </el-col>
    <el-col class="tar w80 mgr20">
      <!-- <el-badge
        is-dot
        class="mgr20 cup"
        title="任务消息"
        @click.native="clickMessage"
      >
        <i class="el-icon-message-solid fz20"></i>
      </el-badge>
      <Dialog title="任务消息列表" v-model="showMessage" :width="600">
        <template v-slot:body>
          <Table :columns="messageColumns" :tableData="messageData"></Table>
        </template>
      </Dialog> -->
      <el-popover placement="bottom-end" trigger="hover">
        <span slot="reference" class="cup" style="line-height: 60px">
          <el-avatar
            icon="el-icon-user-solid"
            class="vam"
            :size="35"
            :src="userInfo.attId ? getUserIcoUrl + userInfo.attId : ''"
          ></el-avatar>
        </span>
        <div class="user-opera">
          <i class="el-icon-user" style="line-height: 30px"></i>
          {{ userName }}
          <el-button type="text" class="flr" size="small" @click="handleLogout"
            >退出</el-button
          >
          <el-button
            type="text"
            class="flr"
            size="small"
            @click="handleUpdatePassword"
            >密码修改</el-button
          >
          <el-button
            type="text"
            class="flr"
            size="small"
            @click="uploadUserIcon"
            >头像上传</el-button
          >
        </div>
        <!-- <el-button v-if="gameShow" size="mini" icon="el-icon-star-off" circle class="w_100" @click="showGameDialog=true;">休闲一刻</el-button> -->
      </el-popover>
    </el-col>
    <Dialog title="密码修改" v-model="showEditDialog" :width="600">
      <template v-slot:body>
        <el-form
          ref="updatePasswordForm"
          :model="updatePasswordForm"
          :rules="updatePasswordRules"
        >
          <el-form-item prop="oldPassword">
            <el-input
              type="password"
              size="large"
              v-model="updatePasswordForm.oldPassword"
              placeholder="请输入旧密码"
            >
              <i class="el-icon-lock" slot="prepend"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="newPassword">
            <el-input
              type="password"
              size="large"
              v-model="updatePasswordForm.newPassword"
              placeholder="请输入新密码"
            >
              <i class="el-icon-lock" slot="prepend"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="lastPassword">
            <el-input
              type="password"
              size="large"
              v-model="updatePasswordForm.lastPassword"
              placeholder="请输入新密码"
            >
              <i class="el-icon-lock" slot="prepend"></i>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="tar">
          <el-button type="primary" size="small" @click="savePassword()"
            >保存</el-button
          >
          <el-button class="mgl10" size="small" @click="showEditDialog = false"
            >取消</el-button
          >
        </div>
      </template>
    </Dialog>
    <Dialog title="头像上传" v-model="showUserIconDialog" :width="600">
      <template v-slot:body>
        <UploadFile
          v-model="fileList"
          :multiple="false"
          accept=".jpg,.png"
        ></UploadFile>
        <div class="tar">
          <el-button type="primary" size="small" @click="saveUserIcon()"
            >保存</el-button
          >
          <el-button
            class="mgl10"
            size="small"
            @click="showUserIconDialog = false"
            >取消</el-button
          >
        </div>
      </template>
    </Dialog>
    <Dialog title="贪吃蛇" v-model="showGameDialog" :width="700">
      <template v-slot:body>
        <Snake v-if="showGameDialog"></Snake>
      </template>
    </Dialog>
  </el-row>
</template>

<script>
//导入组件
import Dialog from "_c/dialog";
import UploadFile from "_c/uploadFile";
import Table from "_c/table";
import Snake from "@/views/home/Snake.vue";
//导入数据
import { pubAttApi } from "@/api/common.js";
import { userApi } from "@/api/auth.js";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    Dialog,
    UploadFile,
    Table,
    Snake,
  },
  data() {
    return {
      isMounted: false,
      sysConfig: this.$sysConfig || {},
      activeIndex: "1",
      menus: [],
      userName: "",
      getUserIcoUrl: pubAttApi.downloadFileByAttId + "?attId=",
      showGameDialog: false,
      gameShow: process.env.VUE_APP_CURRENTMODE !== "prod" ? true : false,
      showEditDialog: false,
      updatePasswordForm: {
        oldPassword: "",
        newPassword: "",
        lastPassword: "",
      },
      updatePasswordRules: {
        oldPassword: {
          required: true,
          message: "请输入旧密码！",
          trigger: "blur",
        },
        newPassword: [
          { required: true, message: "请输入新密码！", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (this.validatePassword(value)) {
                callback();
              } else {
                callback(new Error("请输入8-20有效字符，包含数字和字母！"));
              }
            },
            trigger: "blur",
          },
        ],
        lastPassword: [
          { required: true, message: "请再次输入新密码！", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value === this.updatePasswordForm.newPassword) {
                callback();
              } else {
                callback(new Error("请输入相同密码！"));
              }
            },
            trigger: "blur",
          },
        ],
      },
      fileList: [],
      showUserIconDialog: false,
      showMessage: false, //是否显示消息弹框
      messageColumns: [
        { title: "序号", align: "center", width: "70", type: "index" },
        { title: "消息详情", align: "center", key: "message" },
      ], //
      messageData: [],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
    }),
  },
  mounted() {
    this.$nextTick(function () {
      this.isMounted = true;
      this.initPageData();
    });
  },
  activated() {
    //页面激活
    this.$nextTick(function () {
      if (this.isMounted) {
        this.isMounted = false;
        return;
      }
      this.initPageData();
    });
  },
  methods: {
    ...mapActions(["logout", "updatePassword"]),
    /**初始化页面数据 */
    initPageData() {
      this.userName = this.userInfo.name;
      //获取菜单信息
      var modules = this.userInfo.modules || [];
      let menuList = [];
      for (let i = 0; i < modules.length; i++) {
        if (modules[i].type == "01") {
          menuList.push(modules[i]);
        }
      }
      var treeData = {};
      this.setMenus(treeData, menuList, null);
      this.menus = treeData.children;
      this.filterMenus(this.menus);
    },
    setMenus(treeData, data, value) {
      //设置菜单数据
      treeData["children"] = [];
      for (var i = 0; i < data.length; i++) {
        var item = data[i];
        if (item.pcode == value) {
          this.setMenus(item, data, item.code);
          treeData["children"].push(item);
          //实训模块添加活动分类菜单
          // if (item.code == "trainingTask") {
          //   let activityClsList = this.userInfo.activityClsList || [];
          //   for (let j = 0; j < activityClsList.length; j++) {
          //     item.children.push({
          //       pcode: item.code || "",
          //       code: activityClsList[j].id || "",
          //       name: activityClsList[j].name || "",
          //       url: "/trainingTask?id=" + activityClsList[j].id,
          //     });
          //   }
          // }
        }
      }
    },
    filterMenus(menus) {
      //过滤菜单数据
      for (let i = 0; i < menus.length; i++) {
        let item = menus[i];
        if (!item.children || item.children.length < 1) {
          item.children = undefined;
        } else {
          this.filterMenus(item.children);
        }
      }
    },
    handleSelect(route) {
      //菜单选择事件
      this.$emit("on-select-menu", route);
    },
    handleLogout(command) {
      //退出登录页面
      this.logout();
    },
    /**密码修改 */
    handleUpdatePassword() {
      this.showEditDialog = true;
      let updatePasswordFormRef = this.$refs["updatePasswordForm"];
      if (updatePasswordFormRef) {
        updatePasswordFormRef.resetField();
      }
    },
    savePassword() {
      //保存密码
      this.$refs["updatePasswordForm"].validate((valid) => {
        if (valid) {
          this.updatePassword({
            newPassword: this.updatePasswordForm.newPassword,
            oldPassword: this.updatePasswordForm.oldPassword,
            userId: this.userInfo.userId,
          }).then((res) => {
            if (res.code == "1") {
              this.showEditDialog = false;
              this.$message.success("密码修改成功!");
              this.logout();
            } else {
              this.$message.warning(res.errorMessage || "密码修改失败!");
            }
          });
        }
      });
    },
    validatePassword(value) {
      //校验密码：必须包含8-20个字母、数字混合
      var patrn = /^.*(?=.{8,20})(?=.*\d)(?=.*[a-zA-Z]).*$/; //(?=.*[!@#$%^&*?\(\)])
      if (!patrn.exec(value)) return false;
      return true;
    },
    /**用户头像上传 */
    uploadUserIcon() {
      this.showUserIconDialog = true;
    },
    /**保存用户头像 */
    saveUserIcon() {
      if (this.fileList.length == 0) {
        this.$message.warning("请选择上传头像文件！");
        return;
      }
      let file = this.fileList[0] || {};
      let raw = file.raw || {};
      if (!raw.type || raw.type.indexOf("image") < 0) {
        this.$message.warning("请上传图片文件！");
        return;
      }
      this.$http
        .post(userApi.saveUserPicture, {
          userId: this.userInfo.userId,
          attId: file.id,
        })
        .then((res) => {
          if (res.code == "1") {
            this.$message.success("用户头像上传成功！");
            this.showUserIconDialog = false;
            location.reload();
          } else {
            this.$message.error(res.errorMessage || "用户头像上传失败！");
          }
        });
    },
    /**点击查看用户消息 */
    clickMessage() {
      this.showMessage = true;
    },
  },
};
</script>
<style lang="less">
.main-menu {
  //菜单居中处理
  display: table;
  margin: 0 auto !important;
  text-align: center;
  background-color: #2a82e4 !important;
  li {
    background-color: #2a82e4;
    // color: #fff;
    // &:hover {
    //   color: #409eff !important;
    //   background-color: rgb(217, 236, 255) !important;
    // }
  }
  &.el-menu.el-menu--horizontal {
    border-bottom: solid 0px #e6e6e6;
  }
  .el-submenu__icon-arrow {
    color: #fff;
  }
  &.el-menu--horizontal > .el-menu-item {
    color: #fff;
  }
  &.el-menu--horizontal > .el-submenu .el-submenu__title {
    color: #fff;
    &:hover {
      color: #000;
      .el-submenu__icon-arrow {
        color: #000;
      }
    }
  }
  &.el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 3px solid #409eff;
    color: #409eff !important;
    background-color: rgb(217, 236, 255) !important;
  }
  &.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    border-bottom: 3px solid #409eff;
    color: #409eff !important;
    background-color: rgb(217, 236, 255) !important;
  }
}
.hidden-menu {
  overflow-y: hidden;
  &:hover {
    overflow-y: visible !important;
  }
}
.user-opera {
  .el-button + .el-button {
    margin-left: 0px;
  }
}
</style>
