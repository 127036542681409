import Vue from 'vue';
//IE11 el-menu鼠标滑过报错处理
(function (window) {
  try {
    new MouseEvent('test');
    return false; // No need to polyfill
  } catch (e) {
    // Need to polyfill - fall through
  }
  // Polyfills DOM4 MouseEvent
  var MouseEventPolyfill = function (eventType, params) {
    params = params || { bubbles: false, cancelable: false };
    var mouseEvent = document.createEvent('MouseEvent');
    mouseEvent.initMouseEvent(eventType,
      params.bubbles,
      params.cancelable,
      window,
      0,
      params.screenX || 0,
      params.screenY || 0,
      params.clientX || 0,
      params.clientY || 0,
      params.ctrlKey || false,
      params.altKey || false,
      params.shiftKey || false,
      params.metaKey || false,
      params.button || 0,
      params.relatedTarget || null
    );
    return mouseEvent;
  }
  MouseEventPolyfill.prototype = Event.prototype;
  window.MouseEvent = MouseEventPolyfill;
})(window);
/**
 * 插件导入
 */
import {
  Container,
  Header,
  Main,
  Menu,
  MenuItem,
  Submenu,
  Row,
  Col,
  Table,
  TableColumn,
  Pagination,
  Card,
  Select,
  Option,
  ButtonGroup,
  Button,
  Dialog,
  Tabs,
  Drawer,
  TabPane,
  Autocomplete,
  Badge,
  Form,
  FormItem,
  Input,
  InputNumber,
  DatePicker,
  Upload,
  Tooltip,
  Tag,
  Popover,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Avatar,
  Tree,
  Loading,
  MessageBox,
  Message,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Steps,
  Step,
  Collapse,
  CollapseItem, Cascader, Calendar, Progress, Divider,
  Breadcrumb, BreadcrumbItem, Footer, Timeline, TimelineItem, Alert, Popconfirm, Empty
} from 'element-ui';
/**
 * 插件注册
 */
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Card);
Vue.use(Select);
Vue.use(Option);
Vue.use(ButtonGroup);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Tabs);
Vue.use(Drawer);
Vue.use(TabPane);
Vue.use(Autocomplete);
Vue.use(Badge);
Vue.use(Form);
Vue.use(Empty);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(DatePicker);
Vue.use(Upload);
Vue.use(Tooltip);
Vue.use(Tag);
Vue.use(Popover);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Avatar);
Vue.use(Tree);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Collapse);
Vue.use(CollapseItem); Vue.use(Cascader); Vue.use(Calendar); Vue.use(Progress); Vue.use(Divider);
Vue.use(Breadcrumb); Vue.use(BreadcrumbItem); Vue.use(Footer);
Vue.use(Timeline); Vue.use(TimelineItem); Vue.use(Alert); Vue.use(Popconfirm);
Vue.use(Loading.directive);
/**
 * vue-elementui全局属性配置
 */
// Vue.prototype.$message = Message;
//全局配置消息弹框
Vue.prototype.$message = function () {
  Message()
}
Vue.prototype.$message.closeAll = function () {
  Message.closeAll()
}
Vue.prototype.$message.close = function () {
  Message.close()
}
Vue.prototype.$message.success = function (msg) {
  Message.closeAll();
  return Message.success({
    message: msg,
    showClose: true
  })
}
Vue.prototype.$message.warning = function (msg) {
  Message.closeAll();
  return Message.warning({
    message: msg,
    showClose: true
  })
}
Vue.prototype.$message.error = function (msg) {
  Message.closeAll();
  return Message.error({
    message: msg,
    showClose: true
  })
}
Vue.prototype.$message.info = function (msg) {
  Message.closeAll();
  return Message.info({
    message: msg,
    showClose: true
  })
}
Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$alert = MessageBox.alert;