/**其他通用服务地址 */

/**
 * 代理转发配置
 */
const gatewayApi = process.env.NODE_ENV == "production" ? "/api" : "/api";
const attApi = process.env.NODE_ENV == "production" ? "/api/api-fileview" : "/api/api-fileview";
const commonApi = process.env.NODE_ENV == "production" ? "/api/api-t2-common" : "/api/api-t2-common";

//网关登录管理
export const loginApi = {
  login: gatewayApi + "/login",//用户登录
  logout: gatewayApi + "/logout",//用户登录退出
};

//文件预览
export const attApiUrl = {
  view: attApi + "/onlinePreview?url=",
}

//字典相关接口
export const dictApi = {
  getAllDictDetail: commonApi + "/dict/getAllDictDetail",//查询所有字典详情列表
  getDetailByCode: commonApi + "/dict/getDetailByCode",//查询指定字典详情
}

//附件文件相关接口
export const pubAttApi = {
  getNameById: commonApi + "/pubAtt/getNameById",//查询附件名称
  saveFile: commonApi + "/pubAtt/uploadFile",//保存文件
  deleteFile: commonApi + "/pubAtt/deleteFile",//删除文件
  downloadFile: commonApi + "/pubAtt/downloadFile",//下载文件
  downloadFileByAttId: commonApi + "/pubAtt/downloadFileByAttId",//下载文件(通过附件id)
}