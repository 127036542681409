/**权限、角色、人员、组织 */

/**
 * 系统权限接口
 */
const authApi = process.env.NODE_ENV == "production" ? "/api/api-t2-auth" : "/api/api-t2-auth";
//用户管理
export const userApi = {
  getUserById: authApi + "/user/getUserById",//查询用户信息
  getUserList: authApi + "/user/getUserList",//获取用户列表
  addUser: authApi + "/user/addUser",//用户新增
  updateUser: authApi + "/user/updateUser",//用户编辑
  updateUserEnable: authApi + "/user/updateUserEnable",//用户启动禁用
  resetPassword: authApi + "/user/resetPassword",//重置密码
  updatePassword: authApi + "/user/updatePassword",//修改密码
  getUserResource: authApi + "/user/getUserResource",//查询用户信息及关联资源、组织
  saveUserPicture: authApi + "/user/saveUserPicture",//保存用户头像
  getUserPicture: authApi + "/user/getUserPicture",//获取用户头像流
  getLoginStateList: authApi + "/user/getLoginStateList",//查询组织用户的在线状态

  teacherAddStudent: authApi + "/user/teacherAddStudent",//教师新增学生
  teacherUpdateStudent: authApi + "/user/teacherUpdateStudent",//教师修改学生信息
  studentScanCodeRegister: authApi + "/user/studentScanCodeRegister",//学生扫码注册
  getUserOrgUsers: authApi+ "/user/getUserOrgUsers",//查询组织下所有用户信息
};
//组织管理
export const orgApi = {
  deleteOrg: authApi + "/org/deleteOrg",//组织删除
  getOrgList: authApi + "/org/getOrgList",//获取组织树分页列表
  getOrgListByUser: authApi + "/org/getOrgListByUser",//获取组织树
  getOrgTaskDetail: authApi + "/org/getOrgTaskDetail",//查询任务分配详情
  getUserOrg: authApi + "/org/getUserOrg",//查询用户的组织
  grantOrgToUser: authApi + "/org/grantOrgToUser",//组织授权
  importUser: authApi + "/org/importUser",//导入花名册
  saveTask: authApi + "/org/saveTask",//任务分配
  insertOrg: authApi + "/org/insertOrg",//组织新增
  updateOrg: authApi + "/org/updateOrg",//组织编辑
  updateOrgEnable: authApi + "/org/updateOrgEnable",//组织启动禁用
  saveOrgPaper: authApi + "/org/saveOrgPaper",//保存组件试卷信息
  getAllSchool: authApi + "/org/getAllSchool",//查询所有的院系
  getSchoolAndClassIds: authApi + "/org/getSchoolAndClassIds",//查询院系及其班级组织id列表
  getOrgIdsByUser: authApi + "/org/getOrgIdsByUser",//查询用户创建的组织id列表
};

//角色管理
export const roleApi = {
  addRole: authApi + "/role/addRole",//新增角色
  deleteRole: authApi + "/role/deleteRole",//删除角色
  deleteUserRole: authApi + "/role/deleteUserRole",//删除用户角色关系
  getRoleList: authApi + "/role/getRoleList",//查询角色列表
  getRoleResourceList: authApi + "/role/getRoleResourceList",//获取角色资源树
  getUserOfRole: authApi + "/role/getUserOfRole",//查询角色拥有的用户
  getCanSelectUserOfRole: authApi + "/role/getCanSelectUserOfRole",//查询角色未拥有的用户
  saveRoleResource: authApi + "/role/saveRoleResource",//保存角色资源信息
  saveUserRole: authApi + "/role/saveUserRole",//保存用户角色关系
  updateRole: authApi + "/role/updateRole",//更新角色信息
  getUserRoleDetail: authApi + "/role/getUserRoleDetail",//查询用户拥有的角色详情
  grantRoleToUser: authApi + "/role/grantRoleToUser",//角色授权
  getRoleDetail: authApi + "/role/getRoleDetail",//查询角色详情
  getRolePicture: authApi + "/role/getRolePicture",//获取角色头像流
};

//资源管理
export const resourceApi = {
  deleteResource: authApi + "/resource/deleteResource",//删除资源
  getLoginResources: authApi + "/resource/getLoginResources",//查询用户拥有的资源信息
  getResourceList: authApi + "/resource/getResourceList",//查询资源列表
  saveResource: authApi + "/resource/saveResource",//保存资源
};

//登入日志
export const loginLogApi = {
  getSchoolStat: authApi + "/loginLog/getSchoolStat",//查询学校级别的登录日志统计数据
  getSchoolTeacherStat: authApi + "/loginLog/getSchoolTeacherStat",//查询学校下教师级别的登录日志统计数据
  getUserStatByTeacher: authApi + "/loginLog/getUserStatByTeacher",//查询教师下学生的登录日志统计数据
  getList: authApi + "/loginLog/list", //查询用户登录日志列表
};