import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

const router = new Router({
  // mode: 'history'
})
//引入routers文件夾下面所有js
const routerArrs = require
  .context('.', true, /^\..*\.js$/)
  .keys()
  .filter(t => {
    if (t === './index.js') {
      return false
    } else {
      return true
    }
  })
  .reduce((pre, next) => {  //添加所有的引用
    return pre.concat(require('' + next).default)
  }, []);
//添加路由配置
router.addRoutes(routerArrs);
//路由跳转
const turnTo = (to, next) => {
  if (to.path == "/login" || to.path == "/register") {
    next()
    return;
  }
  if (store.state.user.routerPath == "") {//获取首页路由地址
    store.dispatch("getRouterPath");
  }
  if (to.name == null || to.name == "") {//路由不存在
    next({
      path: store.state.user.routerPath // 默认调转菜单页面
    })
  } else {
    var userInfo = store.state.user.userInfo || {};
    var modules = userInfo.modules || [];
    if (modules == null || modules.length == 0) {//空菜单数据跳转处理
      if (to.name !== "默认页") {
        next({ path: "content" })
      } else {
        next()
      }
      return;
    }
    //跳转校验菜单是否存在该路由地址
    var userAuth = validateModules(modules, to, next);
    if (!userAuth) {  // 跳转校验不通过，跳到默认页面
      next({ path: store.state.user.routerPath })
    } else {  //调转到已选页面
      next()
    }
  }
}
//路由跳转前处理方法
router.beforeEach((to, from, next) => {
  //用户登录判断
  if (to.path != "/login" && to.path != "/register" && !store.state.user.token) {//判断用户是否授权
    next({ path: "login" })
  } else {//用户已授权
    //获取用户相关信息
    getUserInfo(to, next);
  }
})
/**
 * 获取用户相关信息
 */
const getUserInfo = (to, next) => {
  if (to.path != "/login" && to.path != "/register" && store.state.user.userInfo == null) {
    store.dispatch("getUserInfo").then(() => {//获取用户相关信息
      //路由跳转
      turnTo(to, next);
    }).catch(error => {
      //路由跳转
      turnTo(to, next);
    });
  } else {
    //路由跳转
    turnTo(to, next);
  }
}
/**
 * 初始化页面数据
 */
const initPageData = () => {
  if (store.state.user.dictItem == null) {//判断是否存在模块配置信息、字典信息
    store.dispatch("initPageData");
  }
}
//路由跳转后处理方法
router.afterEach((to, from) => {
  if (to.path != "/login" && to.path != "/register") {
    //初始化页面数据
    initPageData();
    //获取面包屑
    store.dispatch("getBreadcrumbList", to);
    //获取当前路由菜单按钮列表
    store.dispatch("getCurrentMenuBtnList", to.path);
  }
})
//跳转校验
const validateModules = (modules, to, next) => {
  if (to.path == "/login" || to.path == "/register") {
    return true;
  }
  var userAuth = false;
  for (var i = 0; i < modules.length; i++) {
    var item = modules[i];
    if (item.url == to.path || to.path.indexOf("/" + item.pcode) == 0) {
      userAuth = true;
    }
  }
  return userAuth;
}
export default router