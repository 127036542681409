<template>
  <div>
    <el-row>
      <el-col :span="16">
        <canvas id="can" width="400" height="400" style="background-color: black">对不起，您的浏览器不支持canvas</canvas>
      </el-col>
      <el-col :span="8">
        <div class="pdb10">----------------操作---------------</div>
        <div>
          <div class="pdb5">按键操作：</div>
          <div class="pdl10 pdb20">
            <i class="el-icon-top c_success pdr5">向上</i>
            <i class="el-icon-bottom c_success pdr5">向下</i>
            <i class="el-icon-back c_success pdr5">向左</i>
            <i class="el-icon-right c_success">向右</i>
          </div>
        </div>
        <div v-if="status=='0'">
          <el-button type="primary" plain @click="start" class="mgb10 w_100" size="small">游戏开始</el-button>
        </div>
        <div v-else>
          <el-button type="primary" plain @click="reStart" class="mgb10 w_100" size="small">游戏重置</el-button>
        </div>
        <div v-if="!pause">
          <el-button
            type="primary"
            plain
            @click="setPause(true)"
            class="mgb10 w_100"
            size="small"
          >游戏暂停</el-button>
        </div>
        <div v-else>
          <el-button
            type="primary"
            plain
            @click="setPause(false)"
            class="mgb10 w_100"
            size="small"
          >游戏继续</el-button>
        </div>
        <div class="pdt10">
          <div class>游戏分数：</div>
          <div class="tac fz28 c_warning">{{fraction}}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      snake: [41, 40], //snake队列表示蛇身，初始节点存在但不显示
      direction: 1, //1表示向右，-1表示向左，20表示向下，-20表示向上
      food: 43, //食物的位置
      n: null, //与下次移动的位置有关
      box: null, //从0到399表示box里[0~19]*[0~19]的所有节点，每20px一个节点
      status: 0, //游戏状态(0-未开始，1-进行中，2-已结束)
      pause: false, //游戏暂停
      timmer: null, //定时对象
      fraction: 0 //分数
    };
  },
  mounted() {
    this.$nextTick(function() {
      this.box = document.getElementById("can").getContext("2d");
      document.onkeydown = evt => {
        //当键盘上下左右键摁下的时候改变direction
        this.direction =
          this.snake[1] - this.snake[0] ==
          (this.n =
            [-1, -20, 1, 20][(evt || event).keyCode - 37] || this.direction)
            ? this.direction
            : this.n;
      };
    });
  },
  methods: {
    /**游戏开始 */
    start() {
      //游戏暂停
      if (this.pause) {
        return;
      }
      this.snake.unshift((this.n = this.snake[0] + this.direction));
      //此时的n为下次蛇头出现的位置，n进入队列
      if (
        this.snake.indexOf(this.n, 1) > 0 ||
        this.n < 0 ||
        this.n > 399 ||
        (this.direction == 1 && this.n % 20 == 0) ||
        (this.direction == -1 && this.n % 20 == 19)
      ) {
        //if语句判断贪吃蛇是否撞到自己或者墙壁，碰到时返回，结束程序
        this.$message.warning("哎呀，游戏结束!");
        this.status = 2;
        return;
      }
      this.draw(this.n, "lime"); //画出蛇头下次出现的位置
      if (this.n == this.food) {
        //如果吃到食物时，产生一个蛇身以外的随机的点，不会去掉蛇尾
        while (this.snake.indexOf((this.food = ~~(Math.random() * 400))) > 0);
        this.calcFraction();
        this.draw(this.food, "yellow");
      } else {
        //没有吃到食物时正常移动，蛇尾出队列
        this.draw(this.snake.pop(), "black");
      }
      this.status = 1;
      if (this.timmer) {
        clearTimeout(this.timmer);
      }
      this.timmer = setTimeout(() => {
        this.start();
      }, 150);
      //每隔0.15秒执行函数一次，可以调节蛇的速度
    },
    /**游戏重置 */
    reStart() {
      this.snake = [41, 40];
      this.direction = 1;
      this.food = 43;
      this.n = null;
      this.pause = false;
      var c = document.getElementById("can");
      this.box = c.getContext("2d");
      c.height = c.height;
      this.start();
    },
    /**游戏暂停状态设置 */
    setPause(pause) {
      this.pause = pause;
      if (!pause) {
        this.start();
      }
    },
    /**分数计算 */
    calcFraction() {
      var snakeLength = this.snake.length;
      if (snakeLength > 3) {
        this.fraction = (snakeLength - 3) * 10;
      }
    },
    draw(seat, color) {
      this.box.fillStyle = color;
      this.box.fillRect((seat % 20) * 20 + 1, ~~(seat / 20) * 20 + 1, 18, 18);
      //用color填充一个矩形，以前两个参数为x，y坐标，后两个参数为宽和高。
    }
  }
};
</script>
<style lang="less">
</style>