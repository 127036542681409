/**
 * 公共路由地址
 */
import Main from '@/views/Main.vue'

export default [
  {
    path: '/systemManage',
    name: '系统管理',
    component: Main,
    children: [
      {
        path: 'userManage',
        name: '用户管理',
        meta: { role: "admin" },
        component: () => import('@/views/teachManage/personManage/PersonManage.vue')
      }, {
        path: 'roleManage',
        name: '角色管理',
        component: () => import('@/views/systemManage/RoleManage.vue')
      }, {
        path: 'moduleManage',
        name: '资源管理',
        component: () => import('@/views/systemManage/ModuleManage.vue')
      }, {
        path: 'facultyClassAdminManage',
        name: '组织管理',
        component: () => import('@/views/teachManage/facultyClassManage/FacultyClassAdminManage.vue')
      }, {
        path: 'evaluateElement',
        name: '评价要素管理',
        component: () => import('@/views/systemManage/evaluateElement/EvaluateElement.vue')
      }
    ]
  },
  {
    path: '/logManage',
    name: '日志管理',
    component: Main,
    children: [
      {
        path: 'accessLog',
        name: '访问日志',
        component: () => import('@/views/logManage/accessLog/index')
      },
      {
        path: 'loginLog',
        name: '登入日志',
        component: () => import('@/views/logManage/loginLog/index')
      },
      {
        path: 'courseLog',
        name: '课件日志',
        component: () => import('@/views/logManage/courseLog/index')
      },
      {
        path: 'trainLog',
        name: '实训日志',
        component: () => import('@/views/logManage/trainLog/index')
      },
    ]
  }
]
