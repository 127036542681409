/**
 * 公共路由地址
 */
import Main from '@/views/Main.vue'

export default [
  {
    path: '/',
    name: '',
    component: Main,
    children: [
      {
        path: 'home',
        name: '我的主页',
        component: () => import('@/views/home/Home.vue')
      },
      {
        path: 'flowChart',
        name: '流程图',
        component: () => import('@/views/home/FlowChart.vue')
      },
      {
        path: 'resourceLibTea',
        name: '教学资源库',
        component: () => import('@/views/teachResourceLib/resourceLibTea/resourceLibTea.vue')
      },
      {
        path: 'resourceLibStu',
        name: '教学资源库',
        component: () => import('@/views/teachResourceLib/resourceLibStu/resourceLibStu')
      },
      {
        path: 'dataSoruceManage',
        name: '数据作业资源管理',
        component: () => import('@/views/dataSoruceManage/DataSoruceManage.vue')
      },
      {
        path: 'dataWorkTask',
        name: '数据作业实训',
        component: () => import('@/views/trainingTask/dataWorkTask/DataWorkTask.vue')
      },
    ]
  },
  {
    path: '/teachManage',
    name: '教学管理',
    component: Main,
    children: [
      {
        path: 'coursewareManage',
        name: '课件管理',
        component: () => import('@/views/teachManage/coursewareManage/CoursewareManage.vue')
      },
      {
        path: 'facultyClassManage',
        name: '班级管理',
        component: () => import('@/views/teachManage/facultyClassManage/FacultyClassManage.vue')
      },
      {
        path: 'personManage',
        name: '学生管理',
        meta: { role: "teacher" },
        component: () => import('@/views/teachManage/personManage/PersonManage.vue')
      },
      {
        path: 'questionPaperManage',
        name: '试题和试卷管理',
        component: () => import('@/views/teachManage/questionManage/QuestionManage.vue')
      },
      {
        path: 'questionManage',
        name: '试题管理',
        component: () => import('@/views/teachManage/questionManage/components/questionLibrary/QuestionLibrary.vue')
      },
      {
        path: 'paperManage',
        name: '试卷管理',
        component: () => import('@/views/teachManage/questionManage/components/paperLibrary/PaperLibrary.vue')
      },
      {
        path: 'questionGrades',
        name: '试题成绩',
        component: () => import('@/views/teachManage/questionGrades/QuestionGrades.vue')
      },
      {
        path: 'teachEvaluate',
        name: '教学评价',
        component: () => import('@/views/teachManage/teachEvaluate/TeachEvaluate.vue')
      },
      {
        path: 'caseTaskManage',
        name: '案例和任务管理',
        component: () => import('@/views/teachManage/caseManage/CaseTaskManage.vue')
      },
      {
        path: 'caseManage',
        name: '内部环境案例',
        component: () => import('@/views/teachManage/caseManage/CaseManage.vue')
      },
      {
        path: 'inEnvironmentManage',
        name: '内部环境管理',
        component: () => import('@/views/teachManage/inEnvironmentManage/InEnvironmentManage.vue')
      },
      {
        path: 'inConEvalManage',
        name: '内控评价管理',
        component: () => import('@/views/teachManage/inConEvalManage/InConEvalManage.vue')
      },
      {
        path: 'riskControlManage',
        name: '数智风控管理',
        component: () => import('@/views/teachManage/riskControlManage/RiskControlManage.vue')
      },
    ]
  },
  {
    path: '/onlineStudyExam',
    name: '在线学习与考试',
    component: Main,
    children: [
      {
        path: 'myCourses',
        name: '我的课程',
        component: () => import('@/views/onlineStudyExam/myCourses/MyCourses.vue')
      },
      {
        path: 'myTestPaper',
        name: '我的试卷',
        component: () => import('@/views/onlineStudyExam/myTestPaper/MyTestPaper.vue')
      },
      {
        path: 'scoreQuery',
        name: '分数查询',
        component: () => import('@/views/onlineStudyExam/scoreQuery/ScoreQuery.vue')
      },
    ]
  },
  {
    path: '/trainingManage',
    name: '实训管理',
    component: Main,
    children: [
      {
        path: 'activityManage',
        name: '控制活动管理',
        component: () => import('@/views/trainingManage/activityManage/ActivityManage')
      },
      {
        path: 'inConEvaluateManage',
        name: '内控评价案例',
        component: () => import('@/views/trainingManage/inConEvaluateManage/InConEvaluateManage.vue')
      },
      {
        path: 'riskControlManage',
        name: '数智风控案例管理',
        component: () => import('@/views/trainingManage/riskControlManage/RiskControlManage.vue')
      }
    ]
  },
  {
    path: '/trainingTask',
    name: '实训任务',
    component: Main,
    children: [
      {
        path: 'controlActivity',
        name: '控制活动',
        component: () => import('@/views/trainingTask/ControlActivity.vue')
      },
      {
        path: 'inEnvironment',
        name: '内部环境',
        component: () => import('@/views/trainingTask/inEnvironment/InEnvironment.vue')
      },
      {
        path: 'inConEval',
        name: '内控评价',
        component: () => import('@/views/trainingTask/inConEval/InConEval.vue')
      },
      {
        path: 'riskControlCase',
        name: '数智风控实训案例',
        component: () => import('@/views/trainingTask/riskControlCase')
      },
    ]
  },
]
