import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import http from '@/libs/http.js';
import { formatDate } from '@/libs/dateTools.js'
import '@/plugins/element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//自定义样式
import '@/style/style.less';
//引入icons图标
import "@/assets/icons/iconfont.css"
import "@/assets/icons-js/iconfont.css"
//浏览器兼容
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import logo from "@/assets/images/dib_logo.png";

Vue.prototype.$http = http

Vue.config.productionTip = false
/**日期转json重写格式化， 避免出现时区不一致导致日期往前减了一天*/
Date.prototype.toJSON = function () { return formatDate(this); }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

const sysConfig = {
  logo: logo,//系统logo
  title: "企业内部控制实训教学平台"
}
Vue.prototype.$sysConfig = sysConfig;
/**初始化系统页面配置 */
const initSysPage = () => {
  var link = document.querySelector('link[rel*="icon"]');
  document.title = sysConfig.title;
};

initSysPage();