/**
 * 日期处理工具
 */
/**
 * 计算时间差
 * @param {*} date2 被减数日期
 * @param {*} date1 减数日期
 * @param {*} type 返回差值类型
 */
export const getIntervalTime = function (date2, date1, type) {
  var date3 = date2.getTime() - date1.getTime() //时间差的毫秒数

  //计算出相差天数
  var days = (date3 / (24 * 3600 * 1000))

  //计算出小时数

  var leave1 = date3 % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
  var hours = (leave1 / (3600 * 1000))
  //计算相差分钟数
  var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
  var minutes = (leave2 / (60 * 1000))
  //计算相差秒数
  var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
  var seconds = (leave3 / 1000)
  if (type == "days") {
    return Math.ceil(days);
  }
  if (type == "hours") {
    return Math.ceil(hours);
  }
  if (type == "minutes") {
    return Math.ceil(minutes);
  }
  if (type == "seconds") {
    return Math.ceil(seconds);
  }
  return { days: Math.floor(days), hours: Math.floor(hours), minutes: Math.floor(minutes), seconds: Math.ceil(seconds) };
}
/**
 * 获取本地系统时间
 */
export const getLocalTime = () => {
  var date = new Date();
  var year = date.getFullYear();
  var month = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1);
  var day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}
/**
 * 获取当前日期最后一天时间
 */
export const getLastDate = (dateP) => {
  var date = new Date();
  if (dateP) { date = dateP }
  var year = date.getFullYear();
  var month = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1);
  var day = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  return year + "-" + month + "-" + day;
}

/**
 * 日期格式化
 */
export const formatDate = (date, fullTime) => {
  if (date == null || typeof date == "string") { return date }
  var year = date.getFullYear();
  var month = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1);
  var day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  return year + "-" + month + "-" + day + (fullTime ? (" " + hours + ":" + minutes + ":" + seconds) : "");
}

/**
 * 对象日期格式化数据
 * @param {*} item 对象
 */
export const formatItemDate = (item) => {
  for (var pro in item) {
    if (item[pro] instanceof Date) {
      item[pro] = formatDate(item[pro]);
    }
  }
}

/**
 * 数组集合日期格式化数据
 * @param {*} list 数组集合
 */
export const formatListDate = (list) => {
  for (var i = 0; i < list.length; i++) {
    formatItemDate(list[i]);
  }
}

/**
 * 获取某年某月的天数
 * @param {*} year 某年
 * @param {*} month 某月
 */
export const getDaysByYearMonth = (year, month) => {
  var date = new Date(year || 9999, month || 12, 0);
  return date.getDate();
}

/**
 * 获取报告期
 * @param {*} reportYear 报告年份
 * @param {*} reportDateType 报告期类型
 */
export const getReportDate = (reportYear, reportDateType) => {
  switch (reportDateType) {
    //年度
    case "Y": return reportYear + "-12-31"; break;
    //季度
    case "Q1": return reportYear + "-03-31"; break;
    case "Q2": return reportYear + "-06-30"; break;
    case "Q3": return reportYear + "-09-30"; break;
    case "Q4": return reportYear + "-12-31"; break;
    case "Q4": return reportYear + "-12-31"; break;
    case "Q4": return reportYear + "-12-31"; break;
    //月度
    case "M01": return reportYear + "-01-31"; break;
    case "M02": return reportYear + "-02-" + getDaysByYearMonth(reportYear, 2); break;
    case "M03": return reportYear + "-03-31"; break;
    case "M04": return reportYear + "-04-30"; break;
    case "M05": return reportYear + "-05-31"; break;
    case "M06": return reportYear + "-06-30"; break;
    case "M07": return reportYear + "-07-31"; break;
    case "M08": return reportYear + "-08-31"; break;
    case "M09": return reportYear + "-09-30"; break;
    case "M10": return reportYear + "-10-31"; break;
    case "M11": return reportYear + "-11-30"; break;
    case "M12": return reportYear + "-12-31"; break;
    default: return reportYear + "-12-31"; break;
  }
}