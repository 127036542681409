/**
 * 公共路由地址
 */
import Main from '@/views/Main.vue'

export default [
  {
    path: '/',
    name: 'main',
    component: Main,
    children: [
      {
        path: 'content',
        name: '默认页',
        component: () => import('@/views/home/Content.vue')
      }
    ]
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('@/views/loginRegister/Login.vue')
  },
  {
    path: '/register',
    name: '注册',
    component: () => import('@/views/loginRegister/Register.vue')
  }
]
