<template>
  <el-upload
    v-loading="uploadLoading"
    class="upload-demo"
    ref="upload"
    :data="data"
    action=""
    v-bind="$attrs"
    :accept="accept"
    :showFileList="showFileList"
    :on-change="onChangeFile"
    :on-success="onSuccess"
    :on-error="onError"
    :on-remove="onRemoveFile"
    :before-upload="beforeUpload"
    :http-request="uploadFile"
    :file-list="fileList"
    :list-type="listType"
  >
    <slot name="btn" v-if="isUseBtnSlot"></slot>
    <el-button v-else size="small" :type="buttonType" :icon="showBtnIcon && 'el-icon-upload2'">{{
      buttonText
    }}</el-button>
    <div slot="tip">
      <slot name="tip"></slot>
    </div>
  </el-upload>
</template>

<script>
//导入组件
//导入数据
import { mapState, mapGetters } from "vuex";
import { pubAttApi } from "@/api/common";
export default {
  model: {
    prop: "sourceFileList",
    event: "change",
  },
  props: {
    //是否支持多选文件
    multiple: {
      type: Boolean,
      default: true,
    },
    //源文件集合
    sourceFileList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    //额外参数
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //支持文件类型
    accept: {
      type: String,
      default: () => {
        return "";
      },
    },
    //附件类型
    attType: {
      type: String,
      default: () => {
        return "";
      },
    },
    //按钮类型
    buttonType: {
      type: String,
      default: () => {
        return "primary";
      },
    },
    showBtnIcon: {
      type: Boolean,
      default: true,
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
    //按钮文字
    buttonText: {
      type: String,
      default: () => {
        return "上传文件";
      },
    },
    //文件列表的类型
    listType: {
      type: String,
      default: () => {
        return "text";
      },
    },
    //是否启用按钮插槽
    isUseBtnSlot: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      //uploadUrl: pubAttApi.saveFile+"/"+getFilePath, //上传地址
      fileList: [], //文件集合
      uploadLoading: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
      dictItem: (state) => state.user.dictItem || {},
    }),
    ...mapGetters(["getFilePath"]),
  },
  watch: {
    //源文件集合变化监控
    sourceFileList() {
      this.initPageData();
    },
  },
  mounted() {
    //页面渲染
    this.$nextTick(function () {
      this.initPageData();
    });
  },
  methods: {
    /**初始化页面数据**/
    initPageData() {
      //不用客户调用不同服务地址配置
      var resourceCode = this.userInfo.resourceCode || "";
      //初始问题表数据
      this.fileList = [].concat(this.sourceFileList || []);
      this.fileList.forEach((item) => {
        if (item.name == null) {
          item.name = item.attName;
        }
        if (item.attachName != null && item.attachId != null) {
          item.id = item.attachId;
          item.name = item.attachName;
        }
      });
      this.setUploadFileLableTitle();
    },
    /**移除文件事件*/
    onRemoveFile(file, fileList) {
      if (file && file.id) {
        this.$http
          .post(pubAttApi.deleteFile + "/" + file.id, {})
          .then((res) => {
            if (res.code == "1") {
              //this.$message.success("文件删除成功！");
              this.$emit("change", fileList);
            } else {
              this.$message.error(res.errorMessage || "文件删除失败！");
            }
          })
          .catch((error) => {
            this.$message.error("文件删除失败！");
          });
      }
    },
    /**上传文件变更事件**/
    onChangeFile(file, fileList) {
      if (fileList.length == 0) {
        this.fileList = [];
      } else if (!this.multiple && fileList.length > 1) {
        //单文件处理
        this.fileList = [fileList[1]];
      }
      this.setUploadFileLableTitle();
    },
    /**上传文件成功事件**/
    onSuccess(res, file, fileList) {
      if (res.code !== "1") {
        //文件上传失败-移除失败文件
        for (var i = 0; i < this.fileList.length; i++) {
          if (file.uid == this.fileList[i].uid) {
            this.fileList[i].splice(i, 1);
          }
        }
        this.$message.error(res.errorMessage || "文件上传失败！");
        this.$emit("change", this.fileList);
        return;
      }
      //上传成功文件id赋值
      for (var i = 0; i < fileList.length; i++) {
        if (file.uid == fileList[i].uid) {
          fileList[i].id = res.data.id;
          break;
        }
      }
      this.$emit("change", fileList);
    },
    /**上传文件失败事件**/
    onError(err) {
      this.$message.error("文件上传失败！");
    },
    /**上传文件*/
    uploadFile(param) {
      this.$emit("handleUploadFile", param)
      const formData = new FormData();
      formData.append("file", param.file);
      formData.append("userId", this.userInfo.userId);
      let uploadUrl =
        pubAttApi.saveFile + "/" + this.getFilePath("t2", this.attType);
      this.uploadLoading = true;
      this.$http
        .post(uploadUrl, formData)
        .then((res) => {
          this.uploadLoading = false;
          param.onSuccess(res);
          this.$emit('uploadSuccess', res.data)
        })
        .catch((error) => {
          this.uploadLoading = false;
          param.onError();
        });
    },
    /**上传之前*/
    beforeUpload(file, fileList) {
      let name = file.name || "";
      let nameSplitList = name.split(".") || [];
      let type = nameSplitList[nameSplitList.length - 1] || "";
      type = type.toLowerCase();
      if (!this.accept || this.accept.indexOf(type) >= 0) {
        if (
          this.accept &&
          (this.accept.indexOf(".png") >= 0 || this.accept.indexOf(".jpg") >= 0)
        ) {
          let fileSize = file.size / 1024;
          if (fileSize > 1024) {
            this.$message.warning("图片大小不能超过1M，请重新上传！");
            return false;
          }
        }
        this.$emit("beforeUpload");
        return true;
      } else {
        this.$message.warning("该文件类型不支持，请重新上传！");
        return false;
      }
    },
    setUploadFileLableTitle() {
      setTimeout(() => {
        let labelDomList = document.getElementsByClassName(
          "el-upload-list__item-name"
        );
        for (let i = 0; i < labelDomList.length; i++) {
          labelDomList[i].setAttribute("title", labelDomList[i].innerText);
        }
      }, 500);
    },
  },
};
</script>
<style lang="less">
.el-upload-list__item .el-icon-close {
  display: none !important;
}
</style>
